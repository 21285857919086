import React from "react";
import "./contact.css";
import { HiOutlineArrowSmRight } from "react-icons/hi";
// import { TbSend } from "react-icons/tb";
import { FiMail } from "react-icons/fi";
// import emailjs from "@emailjs/browser";

const Contact = () => {
  // const form = useRef();

  //   const sendEmail = async (e) => {
  //     e.preventDefault();

  //     // await emailjs.sendForm(
  //     //   "service_s53x8mc",
  //     //   "template_fwq8n7v",
  //     //   form.current,
  //     //   "cXginQ40keRVEt1YV"
  //     // );
  //     e.target.reset();
  //   };

  return (
    <section className="contact section" id="contact">
      <h2 className="section__title">Let's Connect</h2>
      <span className="section__subtitle">Contact Me</span>

      <div className="contact__container container grid">
        <div className="contact__content">
          <div className="contact__info">
            <div className="contact__card">
              <FiMail className="contact__card-icon" />

              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">nikoloz.9374@gmail.com</span>

              <a
                href="mailto:nikoloz.9374@gmail.com"
                className="contact__button"
              >
                Write Me{" "}
                <HiOutlineArrowSmRight className="contact__button-icon" />
              </a>
            </div>
          </div>
        </div>

        {/* <div className="contact__content">
          <h3 className="contact__title">What's the project?</h3>

          <form ref={form} onSubmit={sendEmail} className="contact__form">
            <div className="contact__form-div">
              <label className="contact__form-tag">Name</label>
              <input
                type="text"
                name="name"
                className="contact__form-input"
                placeholder="Type your name"
              />
            </div>

            <div className="contact__form-div">
              <label className="contact__form-tag">Email</label>
              <input
                type="email"
                name="email"
                className="contact__form-input"
                placeholder="Type your email"
              />
            </div>

            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">Project</label>
              <textarea
                name="project"
                cols="30"
                rows="10"
                className="contact__form-input"
                placeholder="Provide some project details..."
              ></textarea>
            </div>

            <button href="#contact" className="button button--flex">
              Send Message
              <TbSend className="button__icon" width={28} height={28} />
            </button>
          </form>
        </div> */}
      </div>
    </section>
  );
};

export default Contact;
