import React from "react";

const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box">
        <h3 className="about__title">English</h3>
        <span className="about__subtitle">Fluent</span>
      </div>
      <div className="about__box">
        <h3 className="about__title">Georgian</h3>
        <span className="about__subtitle">Native</span>
      </div>
      <div className="about__box">
        <h3 className="about__title">Russian</h3>
        <span className="about__subtitle">Fluent</span>
      </div>
    </div>
  );
};

export default Info;
