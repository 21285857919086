import React from "react";
import { HiOutlineArrowSmDown } from "react-icons/hi";
import { BsMouse } from "react-icons/bs";

const Scroll = () => {
  return (
    <div className="home__scroll">
      <a href="#about" className="home__scroll-button button--flex">
        <BsMouse size={32} color="gray" />
        <span className="home__scroll-name">Scroll Down</span>
        <HiOutlineArrowSmDown className="home__scroll-arrow" />
      </a>
    </div>
  );
};

export default Scroll;
