import React from "react";
import { TypeAnimation } from "react-type-animation";
import { TbSend } from "react-icons/tb";

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">
        <TypeAnimation
          sequence={["Nika", 2000, "Nikoloz", 2000]}
          speed={30}
          repeat={Infinity}
          style={{ color: "gray" }}
        />
        <br />
        Muladze
        <br />
        👋
      </h1>
      <h3 className="home__subtitle">Back-end Developer</h3>
      {/* <p className="home__description"></p> */}

      <a href="#contact" className="button button--flex">
        Say Hello
        <TbSend className="button__icon" width={24} height={24} />
      </a>
    </div>
  );
};

export default Data;
