import React from "react";
import "./skills.css";

// Define your skills data as an array of objects
const skills = [
  {
    category: "Languages",
    badges: [
      {
        name: "JavaScript",
        imageUrl:
          "https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=%23F7DF1E",
      },
      {
        name: "TypeScript",
        imageUrl:
          "https://camo.githubusercontent.com/a00920b123df05b3df5e368e509f18bacd65bc5909698fb42be5f35063550f47/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f747970657363726970742d2532333030374143432e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d74797065736372697074266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Java",
        imageUrl:
          "https://camo.githubusercontent.com/b0648ef7a9b6980ea27c1caaeb06d5c8503dbb4f9b4d9d7ca1df60a5edc14340/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6a6176612d2532334544384230302e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6f70656e6a646b266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Python",
        imageUrl:
          "https://camo.githubusercontent.com/bb64b34d04a01cfa79658e2704085740d88e209c21905d0f5b55ebc87a83aa3a/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f507974686f6e2d4646443433423f7374796c653d666f722d7468652d6261646765266c6f676f3d707974686f6e266c6f676f436f6c6f723d626c7565",
      },
    ],
  },
  {
    category: "Backend",
    badges: [
      {
        name: "Node.js",
        imageUrl:
          "https://camo.githubusercontent.com/0d58facab1be74748c39244ff3d990ae8ddd765af40263ed006219154ba90649/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6e6f64652e6a732d3644413535463f7374796c653d666f722d7468652d6261646765266c6f676f3d6e6f64652e6a73266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Express.js",
        imageUrl:
          "https://camo.githubusercontent.com/1bf0d1f3d3c56a35fb820e063b0fc6fed019ca6999c4c5abe17cfdbe3ce190c3/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f657870726573732e6a732d2532333430346435392e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d65787072657373266c6f676f436f6c6f723d253233363144414642",
      },
      {
        name: "TypeORM",
        imageUrl:
          "https://ziadoua.github.io/m3-Markdown-Badges/badges/TypeORM/typeorm1.svg",
      },
      {
        name: "Swagger",
        imageUrl:
          "https://img.shields.io/badge/-Swagger-%23Clojure?style=for-the-badge&logo=swagger&logoColor=white",
      },
      {
        name: "GraphQL",
        imageUrl:
          "https://img.shields.io/badge/-GraphQL-E10098?style=for-the-badge&logo=graphql&logoColor=white",
      },
      // {
      //   name: "Nest.js",
      //   imageUrl:
      //     "https://camo.githubusercontent.com/c2877247f7c0a5825ef41ff30b86acffd42ac23272514f92d21d9eef7f9cca41/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6e6573746a732d2532334530323334452e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6e6573746a73266c6f676f436f6c6f723d7768697465",
      // },
      // {
      //   name: "Prisma",
      //   imageUrl:
      //     "https://camo.githubusercontent.com/1c117ef68aaeafe875714e3c7074b4c66474c7c13d1702c91509e51763a133d7/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f507269736d612d3339383243453f7374796c653d666f722d7468652d6261646765266c6f676f3d507269736d61266c6f676f436f6c6f723d7768697465",
      // },
    ],
  },
  {
    category: "Databases",
    badges: [
      {
        name: "MongoDB",
        imageUrl:
          "https://camo.githubusercontent.com/7e95531437f8c91626ae46cb69240160dfde5c39c1119c550cd174ba8a19e712/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4d6f6e676f44422d2532333465613934622e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6d6f6e676f6462266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "MySQL",
        imageUrl:
          "https://camo.githubusercontent.com/3fb5c666007b264dde797b2d7e258cae7f336848f3408cef902f04c6065cc146/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6d7973716c2d2532333030662e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6d7973716c266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "PostgreSQL",
        imageUrl:
          "https://camo.githubusercontent.com/bf590679058d9d1074a82721726ea4a5bf048b4b8cce82d01ba8ca32585e0298/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f706f7374677265732d2532333331363139322e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d706f737467726573716c266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Redis",
        imageUrl:
          "https://camo.githubusercontent.com/a963b142be25ca1e2df9adddf3ebe21a5accb30a6d67763f94bd591eeaeeb387/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f72656469732d2532334444303033312e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d7265646973266c6f676f436f6c6f723d7768697465",
      },
    ],
  },
  {
    category: "Linux",
    badges: [
      {
        name: "Linux",
        imageUrl:
          "https://camo.githubusercontent.com/7eefb2ba052806d8a9ce69863c2eeb3b03cd5935ead7bd2e9245ae2e705a1adf/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4c696e75782d4643433632343f7374796c653d666f722d7468652d6261646765266c6f676f3d6c696e7578266c6f676f436f6c6f723d626c61636b",
      },
      {
        name: "Ubuntu",
        imageUrl:
          "https://camo.githubusercontent.com/b51b672b44d8445dbcc388e0beb6122800b2620264b029c1d0d985f94f6e9732/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f5562756e74752d4539353432303f7374796c653d666f722d7468652d6261646765266c6f676f3d7562756e7475266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "CentOS",
        imageUrl:
          "https://camo.githubusercontent.com/4d65364249fdb86c73f012d51a4ae92e252a31e689a02a232bf547feeade68aa/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f63656e742532306f732d3030323236303f7374796c653d666f722d7468652d6261646765266c6f676f3d63656e746f73266c6f676f436f6c6f723d463046304630",
      },
    ],
  },
  {
    category: "DevOps",
    badges: [
      {
        name: "AWS",
        imageUrl:
          "https://camo.githubusercontent.com/46da2c537428d5163a38512194e2110805271a7cc12b54e85cea9c5f53030336/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4157532d2532334646393930302e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d616d617a6f6e2d617773266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Docker",
        imageUrl:
          "https://camo.githubusercontent.com/8396abd667a0eca7d28cdb29ec63b6bf29a7854c7c3d467e6ece648c7e9b81e1/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f646f636b65722d2532333064623765642e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d646f636b6572266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Github Actions",
        imageUrl:
          "https://camo.githubusercontent.com/60f7a4c410c90351f91c96b1db36257342c0615fb0e5d827eda88ce75134c104/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f676974687562253230616374696f6e732d2532333236373145352e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d676974687562616374696f6e73266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Netlify",
        imageUrl:
          "https://camo.githubusercontent.com/2aa883d52783b24f65681fa3a20b76e914ca9cdbda511170ddae263b8c692c2e/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6e65746c6966792d2532333030303030302e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6e65746c696679266c6f676f436f6c6f723d23303043374237",
      },
      {
        name: "Grafana",
        imageUrl:
          "https://img.shields.io/badge/grafana-%23F46800.svg?style=for-the-badge&logo=grafana&logoColor=white",
      },
    ],
  },
  {
    category: "Frontend",
    badges: [
      {
        name: "HTML5",
        imageUrl:
          "https://camo.githubusercontent.com/5e7e215d9ff3a7c2e96d09232c11b2205565c841d1129dd2185ebd967284121f/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f68746d6c352d2532334533344632362e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d68746d6c35266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "CSS3",
        imageUrl:
          "https://camo.githubusercontent.com/6531a4161596e3d9fdab3d0499a7b7ce5c5c8b568be219f3e9707af042e575d2/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f637373332d2532333135373242362e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d63737333266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "React",
        imageUrl:
          "https://camo.githubusercontent.com/3babc94d778f96441b3a66615fb5ee88c6ed04f174ed49b04df92b071a7d0e80/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f72656163742d2532333230323332612e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d7265616374266c6f676f436f6c6f723d253233363144414642",
      },
      {
        name: "Next.js",
        imageUrl:
          "https://camo.githubusercontent.com/2abe53f4176fd7b9639f1c316e77574575c1c99c660e03fefa08299045988ba5/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4e6578742d626c61636b3f7374796c653d666f722d7468652d6261646765266c6f676f3d6e6578742e6a73266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "SCSS",
        imageUrl:
          "https://camo.githubusercontent.com/742126d474d8314230c293fa38b2fa9ff827e5fac43d9808c561be5887a4fb5f/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f534153532d686f7470696e6b2e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d53415353266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Material UI",
        imageUrl:
          "https://camo.githubusercontent.com/d5f2532e735a506e5aeb9c516302d08bce98607b3c1c5a366fe50f3dc2b1b998/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f4d6174657269616c25323055492d3030374646463f7374796c653d666f722d7468652d6261646765266c6f676f3d6d7569266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Material Design",
        imageUrl:
          "https://camo.githubusercontent.com/ddb79ce4a4beab717ee2d2cf6825dfce7f752c78bc572d91163d2d3362ea17c5/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6d6174657269616c25323064657369676e2d3735373537353f7374796c653d666f722d7468652d6261646765266c6f676f3d6d6174657269616c25323064657369676e266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Ant Design",
        imageUrl:
          "https://camo.githubusercontent.com/be53534e35b4f92e73f8b65bf52572de21d9296407f41b18d11f278f3d5739fb/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f416e7425323044657369676e2d3138393046463f7374796c653d666f722d7468652d6261646765266c6f676f3d616e7464657369676e266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Tailwind CSS",
        imageUrl:
          "https://camo.githubusercontent.com/6c62369c4e99b8a15e8bc2252842ba29e6af1a870849ba36b78e534304113437/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f5461696c77696e645f4353532d3338423241433f7374796c653d666f722d7468652d6261646765266c6f676f3d7461696c77696e642d637373266c6f676f436f6c6f723d7768697465",
      },
    ],
  },
  {
    category: "Services & Tools",
    badges: [
      {
        name: "Git",
        imageUrl:
          "https://camo.githubusercontent.com/3d768e26ac10ba994a60ed19acd487895cc43a9cdd43e9305c2408b93136234d/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6769742d2532334630353033332e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d676974266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "JIRA",
        imageUrl:
          "https://camo.githubusercontent.com/ff34428aa0cac52a6e2270085526dac42becf14bff6d3e4182cc8e69d26f9aeb/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6a6972612d2532333041304646462e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6a697261266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Postman",
        imageUrl:
          "https://camo.githubusercontent.com/8bc77ae2c6ec0a97c7692ec54d53c49d3c4637e9c64ee63f7b45cf14a50e8177/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f506f73746d616e2d4646364333373f7374796c653d666f722d7468652d6261646765266c6f676f3d706f73746d616e266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "YAML",
        imageUrl:
          "https://img.shields.io/badge/yaml-%23ffffff.svg?style=for-the-badge&logo=yaml&logoColor=151515",
      },
      {
        name: "Github",
        imageUrl:
          "https://camo.githubusercontent.com/410d86e43f847d3f6e3027fa6f0c2fb7641d893fa601d863a943eac968c41890/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6769746875622d2532333132313031312e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d676974687562266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Gitlab",
        imageUrl:
          "https://camo.githubusercontent.com/adb6b0fd65915500db1990b429a1bfba395dd146324016fb6b192d150fa4daa3/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6769746c61622d2532333138313731372e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6769746c6162266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "ChatGPT",
        imageUrl:
          "https://camo.githubusercontent.com/c8af3418f8fe508aed1c66f474b50f9e9d8f64db648d1bd947527b35b6243a99/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f636861744750542d3734616139633f7374796c653d666f722d7468652d6261646765266c6f676f3d6f70656e6169266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "Figma",
        imageUrl:
          "https://camo.githubusercontent.com/bdb7731529e6563b08b823d27f981683f89fd666d434f6592cb901ba763277f3/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6669676d612d2532334632344531452e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6669676d61266c6f676f436f6c6f723d7768697465",
      },
    ],
  },
  {
    category: "Other",
    badges: [
      {
        name: "Unity",
        imageUrl:
          "https://camo.githubusercontent.com/b1148630e3728ffb774987b47193e6f82887f7027b0f5844f541ccc5672a7ce3/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f756e6974792d2532333030303030302e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d756e697479266c6f676f436f6c6f723d7768697465",
      },
      {
        name: "OpenCV",
        imageUrl:
          "https://camo.githubusercontent.com/17b19bec5d5214f5b217feaeb0bbdb1300e6f47e41b3c15d03a53a774c1db938/68747470733a2f2f696d672e736869656c64732e696f2f62616467652f6f70656e63762d25323377686974652e7376673f7374796c653d666f722d7468652d6261646765266c6f676f3d6f70656e6376266c6f676f436f6c6f723d7768697465",
      },
    ],
  },
  {
    category: "Testing",
    badges: [
      {
        name: "Mocha",
        imageUrl: "https://img.shields.io/badge/-mocha-%238D6748?style=for-the-badge&logo=mocha&logoColor=white"
      }
    ]
  }
];

const Skills = () => {
  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle"></span>
      <div className="skills__container">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Skills</th>
            </tr>
          </thead>
          <tbody>
            {skills.map((skill) => (
              <tr key={skill.category}>
                <td>{skill.category}</td>
                <td>
                  {/* Render all badges for this category */}
                  {skill.badges &&
                    skill.badges.map((badge) => (
                      <img
                        key={badge.name}
                        src={badge.imageUrl}
                        alt={badge.name}
                        style={{ margin: "0 4px" }} // Add some spacing between badges
                      />
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Skills;
