import Project1 from "../../assets//projects/lingwing.png";
import Project2 from "../../assets/projects/social.png";
import Project3 from "../../assets/projects/3dtowerdef.png";
import Project4 from "../../assets/projects/spaceinvaders.png";
import Project5 from "../../assets/projects/8puzzlesolver.png";
import Project6 from "../../assets/projects/image-recognition.jpg";
import Project7 from "../../assets/projects/gamecatalog.png";
import Project8 from "../../assets/projects/minesweeper.png";
import Project9 from "../../assets/projects/earth.png";

export const projectsData = [
  {
    id: 1,
    image: Project1,
    title: "Lingwing",
    category: "Web Development",
    href: "https://lingwing.com",
  },
  {
    id: 2,
    image: Project2,
    title: "Social Media Website",
    category: "Web Development",
    href: "https://github.com/CodeEventHorizon/MERN-Stack-Social-Media",
  },
  {
    id: 3,
    image: Project3,
    title: "3D Tower Defense",
    category: "Game Development",
    href: "https://github.com/CodeEventHorizon/Simple-3D-Tower-Defense/tree/main",
  },
  {
    id: 4,
    image: Project4,
    title: "2D Space Invaders",
    category: "Game Development",
    href: "https://github.com/CodeEventHorizon/Space-Invaders/tree/main",
  },
  {
    id: 5,
    image: Project5,
    title: "8-Puzzle Problem Solver (AI)",
    category: "Algorithms",
    href: "https://github.com/CodeEventHorizon/8-Puzzle-Problem-Solver/tree/main",
  },
  {
    id: 6,
    image: Project6,
    title: "Map Image Recognition",
    category: "Image Recognition",
    href: "https://github.com/CodeEventHorizon/Map-Image-Recognition/tree/main",
  },
  {
    id: 7,
    image: Project7,
    title: "Games Catalog Website",
    category: "Web Development",
    href: "https://github.com/CodeEventHorizon/ce154/tree/main",
  },
  {
    id: 8,
    image: Project8,
    title: "Minesweeper",
    category: "Game Development",
    href: "https://github.com/CodeEventHorizon/ce203/tree/main/Assignment2",
  },
  {
    id: 9,
    image: Project9,
    title: "ETOPO1 Global Relief Model",
    category: "Other",
    href: "https://github.com/CodeEventHorizon/ce152/tree/main",
  },
];

export const projectsNav = [
  {
    name: "All",
  },
  {
    name: "Web Development",
  },
  {
    name: "Game Development",
  },
  {
    name: "Image Recognition",
  },
  {
    name: "Algorithms",
  },
  {
    name: "Other",
  },
];
